import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Container,
  Grid,
  Box,
  FormControl,
  Button,
  MenuItem,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  tableCellClasses,
  FormLabel,
  TableFooter,
  TablePagination,
} from '@mui/material'
import html2canvas from 'html2canvas'

import { styled, useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import LastPageIcon from '@mui/icons-material/LastPage'
import moment from 'moment'
import ReactEcharts from 'echarts-for-react'
import JSPDF from 'jspdf'
import * as api from '../../../utils/api/vendorAutopaySystem/AnalysisChartYearApi'

const ReportCenterTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '15px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'center',
  },
}))

const ReportLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    backgroundColor: '#c6c6c6',
    padding: '3px',
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'Left',
    padding: '3px',
    fontSize: '13px',
  },
}))

const ReportRightTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    padding: '3px',
    fontSize: '13px',
  },
}))

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

// 承判商自動轉帳出糧人數及工數分析圖表(每月)

export default function AnalysisChartYear() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [headList, setHeadList] = useState([])
  const [dateList, setDateList] = useState([])
  const [optionData, setOptionData] = useState([])
  const [sendData, setSendData] = useState({
    chartColor: 'color',
  })
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [income, setIncome] = useState({})
  const [peopleCount, setPeopleCount] = useState({})
  const [workerCount, setWorkerCount] = useState({})
  const [average, setAverage] = useState({})
  const contentRef = useRef(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setBackOpen(true)
    const getData = async () => {
      let getHeadList = await api.getfilljobNatureId()
      let getdateList = await api.getPeriodYear()
      if (getHeadList) {
        setHeadList(getHeadList)
      }

      if (getdateList.length !== 0) {
        setDateList(getdateList)
      } else {
        getdateList.splice(0, 0, { id: '', text: 'nothing' })
        setDateList([...getdateList])
      }
      setBackOpen(false)
    }
    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleHeadLine = (data) => {
    setSendData({
      ...sendData,
      jobNatureId: data.id,
      jobNatureDesc: data.text,
    })
  }

  const handleDate = (data) => {
    setSendData({ ...sendData, dateFrom: data.id })
  }

  const handleChartColor = (e) => {
    setSendData({ ...sendData, chartColor: e.target.value })
  }

  function creatObj(name, list) {
    let x = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1]
    let obj = {
      title: {
        text: name,
      },
      grid: {
        right: '25%',
        bottom: '33%',
        width: '50%',
      },
      xAxis: {
        type: 'category',
        data: x,
      },
      yAxis: {
        type: 'value',
        name: name,
      },
      series: [
        {
          label: {
            show: true,
            position: 'top',
          },
          name: name,
          data: list,
          type: 'line',
          smooth: true,
          barWidth: '30%',
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
    }
    return obj
  }
  const handleSubmit = (e) => {
    console.log(sendData)
    let charList = []

    if (sendData.dateFrom === '') {
      alert('请选择日期')
      return
    }
    const submit = async () => {
      setBackOpen(true)
      let send = await api.submitData(sendData)
      if (send) {
        setTableData(send)
        let incomeList = []
        let peopleCountList = []
        let workerCountList = []
        let averageList = []
        send.map((row, i) => {
          incomeList.push(row.salarywithautopay || 0)
          peopleCountList.push(row.totalworkers || 0)
          workerCountList.push(row.totalworkdayact || 0)
          averageList.push(row.averagesalary || 0)
          return ''
        })
        let incomeObj = creatObj(t('Total_income_of_participating_workers'), incomeList)
        let peopleCountObj = creatObj(t('Total_Participants'), peopleCountList)
        let workerCountObj = creatObj(t('Total_number_of_Workers_involved'), workerCountList)
        let averageObj = creatObj(t('Average_daily_wages'), averageList)
        setBackOpen(false)
        setIsSearchPage(false)
        setIncome({ ...incomeObj })
        setPeopleCount({ ...peopleCountObj })
        setWorkerCount({ ...workerCountObj })
        setAverage({ ...averageObj })
        setOptionData(charList)
      } else {
        alert('error')
        setBackOpen(false)
      }
    }
    submit()
  }

  const resetForm = (e) => {
    setSendData({})
  }

  // 打印
  const handlePrint = async () => {
    try {
      const content = contentRef.current

      if (content) {
        const canvas = await html2canvas(content)
        const imgData = canvas.toDataURL('image/png')

        // 创建一个新窗口用于打印
        const printWindow = window.open('', '', 'width=600,height=600')
        printWindow.document.open()
        printWindow.document.write('<img src="' + imgData + '" />')
        printWindow.document.close()

        // 等待图像加载完成后再执行打印
        printWindow.onload = function () {
          printWindow.print()
          printWindow.close()
        }
      }
    } catch (error) {
      console.error('打印出错：', error)
    }
  }

  const printData = async (htmlTitle, currentTime) => {
    let element = document.getElementById('contentRef')
    let height = parseInt(element.style.height, 10) + 100 + 'px'
    html2canvas(element, {
      logging: false,
    }).then(function (canvas) {
      let pdf = new JSPDF('p', 'mm', 'a4') // A4纸，纵向
      let ctx = canvas.getContext('2d')
      let a4w = 190
      let a4h = 257 // A4大小，210mm x 297mm，四边各保留20mm的边距
      let imgHeight = Math.floor((a4h * canvas.width) / a4w) // 按A4显示比例换算一页图像的像素高度
      let renderedHeight = 0

      while (renderedHeight < canvas.height) {
        let page = document.createElement('canvas')
        page.width = canvas.width
        page.height = Math.min(imgHeight, canvas.height - renderedHeight) // 可能内容不足一页

        // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
        pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, (a4w * page.height) / page.width)) // 添加图像到页面，保留10mm边距

        renderedHeight += imgHeight
        if (renderedHeight < canvas.height) {
          pdf.addPage()
        } // 如果后面还有内容，添加一个空页
        // delete page;
      }
      pdf.save(htmlTitle + currentTime)
    })
  }

  // 承判商自動轉賬支薪報告
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('Contractor_Industry_Annual_Year')}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField sx={{ textAlign: 'left', maxWidth: '400px' }} value={sendData.jobNatureId || ' '} fullWidth size="small" label={t('Head_of_line')} select>
                  <MenuItem value=" " onClick={() => handleHeadLine({ id: ' ', text: 'All' })}>
                    {' '}
                    {t('all_outfits')}
                  </MenuItem>
                  {headList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleHeadLine(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField error={!sendData.dateFrom} sx={{ textAlign: 'left', maxWidth: '400px' }} value={sendData.dateFrom || ''} fullWidth size="small" label={t('date')} select>
                  {dateList.map((item, index) => (
                    <MenuItem key={index} value={item.id} onClick={() => handleDate(item)}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography sx={{ textAlign: 'center' }}>{t('The_past_12_months')}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: 'primaryBlue',
                    color: 'commom.white',
                  }}
                >
                  {t('button_submit')}
                </Button>
                <Button
                  variant="contained"
                  onClick={resetForm}
                  sx={{
                    marginLeft: '20px',
                    backgroundColor: 'primaryGreen',
                    color: 'commom.white',
                    marginRight: '10px',
                  }}
                >
                  {t('button_reset')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}
      {!isSearchPage && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
            <Button
              sx={{ float: 'right', mr: '20px' }}
              onClick={() =>
                printData(
                  sendData.jobNatureDesc
                    ? moment(sendData.dateFrom).format('yyyy') +
                        ' ' +
                        t('year') +
                        ' ' +
                        sendData.jobNatureDesc.substring(4) +
                        ' ' +
                        t('Contractor_Industry_Annual_Year_Type') +
                        '(' +
                        moment(sendData.dateFrom).format('yyyy') +
                        ' ' +
                        t('year') +
                        ' ' +
                        moment(sendData.dateFrom).format('MM') +
                        ' ' +
                        t('month_alone') +
                        ')'
                    : moment(sendData.dateFrom).format('yyyy') +
                        ' ' +
                        t('year') +
                        ' ' +
                        t('all') +
                        ' ' +
                        t('Contractor_Industry_Annual_Year_Type') +
                        '(' +
                        moment(sendData.dateFrom).format('yyyy') +
                        ' ' +
                        t('year') +
                        ' ' +
                        moment(sendData.dateFrom).format('MM') +
                        ' ' +
                        t('month_alone') +
                        ')',
                  new Date(),
                )
              }
            >
              <DownloadIcon />
              {t('列印')}
            </Button>
          </div>
          {/* <div style={{ display: "block", textAlign: "right" }}>
                            <Button sx={{ float: "right", mr: "20px" }} onClick={exportExcel}>
                                <DownloadIcon />
                                {t("button_export_excel")}
                            </Button>
                            <Button sx={{ float: "right", mr: "20px" }}>
                                <DownloadIcon />
                                {t("button_export_pdf")}
                            </Button>
                        </div> */}
          <div>
            <Typography sx={{ my: '10px' }}>
              {t('Print_Date')}:{moment(new Date()).format('yyyy/MM/DD')}
            </Typography>
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              <b>
                {sendData.jobNatureDesc
                  ? moment(sendData.dateFrom).format('yyyy') +
                    ' ' +
                    t('year') +
                    ' ' +
                    sendData.jobNatureDesc.substring(4) +
                    ' ' +
                    t('Contractor_Industry_Annual_Year_Type') +
                    '(' +
                    moment(sendData.dateFrom).format('yyyy') +
                    ' ' +
                    t('year') +
                    ' ' +
                    moment(sendData.dateFrom).format('MM') +
                    ' ' +
                    t('month_alone') +
                    ')'
                  : moment(sendData.dateFrom).format('yyyy') +
                    ' ' +
                    t('year') +
                    ' ' +
                    t('all') +
                    ' ' +
                    t('Contractor_Industry_Annual_Year_Type') +
                    '(' +
                    moment(sendData.dateFrom).format('yyyy') +
                    ' ' +
                    t('year') +
                    ' ' +
                    moment(sendData.dateFrom).format('MM') +
                    ' ' +
                    t('month_alone') +
                    ')'}
              </b>
            </Typography>
            <TableContainer sx={{ mb: '20px', maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table id="contentRef" size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Month')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Total_income_of_participating_workers')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Total_Participants')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Total_number_of_Workers_involved')}</ReportCenterTableCell>
                    <ReportCenterTableCell sx={{ textAlign: 'right' }}>{t('Average_daily_wages')}</ReportCenterTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow
                      key={index}
                      hover
                      sx={{
                        backgroundColor: index % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                      }}
                    >
                      <TableCell sx={{ textAlign: 'right' }}>{row?.datamonth}</TableCell>

                      <TableCell sx={{ textAlign: 'right' }}>{row.salarywithautopay !== null ? row.salarywithautopay.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0.00'}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row?.totalworkers !== null ? row.totalworkers.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0'}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row.totalworkdayact !== null ? row.totalworkdayact.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0'}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{row.averagesalary !== null ? row.averagesalary.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0.00'}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={11}>
                      <Typography variant="h4" style={{ textAlign: 'center' }}>
                        <b>
                          {sendData.jobNatureDesc
                            ? moment(sendData.dateFrom).format('yyyy') +
                              ' ' +
                              t('year') +
                              ' ' +
                              sendData.jobNatureDesc.substring(4) +
                              ' ' +
                              t('Contractor_Industry_Annual_Year_Type') +
                              '(' +
                              moment(sendData.dateFrom).format('yyyy') +
                              ' ' +
                              t('year') +
                              ' ' +
                              moment(sendData.dateFrom).format('MM') +
                              ' ' +
                              t('month_alone') +
                              ')'
                            : moment(sendData.dateFrom).format('yyyy') +
                              ' ' +
                              t('year') +
                              ' ' +
                              t('all') +
                              ' ' +
                              t('Contractor_Industry_Annual_Year_Type') +
                              '(' +
                              moment(sendData.dateFrom).format('yyyy') +
                              ' ' +
                              t('year') +
                              ' ' +
                              moment(sendData.dateFrom).format('MM') +
                              ' ' +
                              t('month_alone') +
                              ')'}
                        </b>
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={11}>
                      <ReactEcharts option={income} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <ReactEcharts option={peopleCount} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <ReactEcharts option={workerCount} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <ReactEcharts option={average} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Container>
      )}
    </>
  )
}
