import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  TableFooter,
  FormLabel,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import $http, { $download } from 'src/utils/api/services'

import { parseInt, set } from 'lodash'
import * as api from '../../../utils/api/vendorAutopaySystem/ReportVendorBasicSalarySearchApi'

export default function ReportVendorBasicSalarySearch() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  // 國際化
  const { t } = useTranslation()

  const [backOpen, setBackOpen] = useState(false)
  const [siteList, setSiteList] = useState([])

  const [empTypeIdList, setEmpTypeIdList] = useState([])

  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [vendorIdList, setVendorIdList] = useState([])
  const [reportFormType, setReportFormType] = useState('details')
  const [lessOrGreat, setLessOrGreat] = useState('true')

  useEffect(() => {
    // setBackOpen(true)

    const getData = async () => {
      let getVendorIdList = await api.getVendorIdList()
      let getSiteList = await api.getSiteList()
      let getEmpTypeIdList = await api.getEmpTypeId()

      if (getVendorIdList) {
        getVendorIdList.splice(0, 0, { id: '', text: 'ALL' })
        setVendorIdList(getVendorIdList)
      }

      if (getSiteList) {
        getSiteList.splice(0, 0, { id: '', text: 'ALL' })
        setSiteList(getSiteList)
      }

      if (getEmpTypeIdList) {
        getEmpTypeIdList.splice(0, 0, { id: '', text: '所有出糧方式' })
        setEmpTypeIdList(getEmpTypeIdList)
      }

      // if (getSmartCard) {
      //   setSmartCard(getSmartCard)
      // }
      //  setBackOpen(false)
      setReportFormType('details')
      setLessOrGreat('true')
      setSendData({
        ...sendData,
        reportForm: 'details',
        lessOrGreat: 'true',
      })
    }

    setSendData({
      ...sendData,
      dateFrom: moment(getPreMonth(moment(new Date()).format('yyyy-MM-DD'))).format('yyyy/MM/DD'),
      dateTo: moment(new Date()).format('yyyy/MM/DD'),
    })
    getData()
  }, [])

  const getPreMonth = (date) => {
    console.log('date', date)
    let arr = date.split('-')
    let year = arr[0] // 获取当前日期的年份
    let month = arr[1] // 获取当前日期的月份
    let day = arr[2] // 获取当前日期的日
    let days = new Date(year, month, 0)
    days = days.getDate() // 获取当前日期中月的天数
    let year2 = year
    let month2 = parseInt(month) - 1
    if (month2 === 0) {
      year2 = parseInt(year2) - 1
      month2 = 12
    }
    let day2 = day
    let days2 = new Date(year2, month2, 0)
    days2 = days2.getDate()
    if (day2 > days2) {
      day2 = days2
    }
    if (month2 < 10) {
      month2 = '0' + month2
    }
    let t2 = year2 + '-' + month2 + '-' + day2
    return t2
  }

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handleSiteId = (e, data) => {
    if (data !== null) {
      setSendData({ ...sendData, siteId: data.id, site: data })
    } else {
      setSendData({ ...sendData, siteId: null, site: null })
    }
  }

  const handleVendorId = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        vendorId: data.id,
        vendorDesc: data.text,
        vendor: data,
      })
    } else {
      setSendData({
        ...sendData,
        vendorId: null,
        vendorDesc: null,
        vendor: null,
      })
    }
  }

  const handleDate = (e) => {
    setSendData({ ...sendData, date: e.target.value })
  }

  const handleEmpTypeId = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        empTypeId: data.id,
        empTypeDesc: data.text,
        empType: data,
      })
    } else {
      setSendData({
        ...sendData,
        empTypeId: null,
        empTypeDesc: null,
        empType: null,
      })
    }
  }

  const handleDateFrom = (e) => {
    setSendData({
      ...sendData,
      dateFrom: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
      dateTo: '',
    })
  }

  const handleDateTo = (e) => {
    setSendData({
      ...sendData,
      dateTo: moment(new Date(e.target.value).getTime()).format('yyyy/MM/DD'),
    })
  }

  const handleReportFormType = (e) => {
    // if (!e || e == null) {
    //   setReportForm(0);
    //   setSendData({
    //     ...sendData,
    //     reportForm: "詳細記錄",
    //   });
    // }

    setReportFormType(e.target.value)
    setSendData({
      ...sendData,
      reportForm: e.target.value === 'details' ? '詳細記錄' : '一覽表',
    })
    console.log(sendData)
  }

  const handleLessOrGreat = (e) => {
    setLessOrGreat(e.target.value)
    setSendData({ ...sendData, lessOrGreat: e.target.value === '小於' })
    console.log(sendData)
  }

  const handleLowWage = (e) => {
    setSendData({ ...sendData, lowWage: e.target.value })
  }

  const handlehighWage = (e) => {
    setSendData({ ...sendData, highWage: e.target.value })
  }

  const handleWageDiffSign = (e) => {
    setSendData({ ...sendData, wageDiffSign: e.target.value })
  }

  const handleSubmit = (e) => {
    if (!sendData.dateFrom || !sendData.dateTo) {
      alert('請選擇日期')
      return
    }
    if (!sendData.lowWage) {
      alert('工人入息(每日平均) 最低')
      return
    }
    if (!sendData.highWage) {
      alert('工人入息(每日平均) 最高')
      return
    }
    if (!sendData.wageDiffSign) {
      alert(t('Difference_between_workers_income_and_registered_average_income'))
      return
    }

    const getData = async () => {
      setBackOpen(true)
      let send = null

      setBackOpen(true)
      if (sendData.reportForm === '詳細記錄') {
        send = await api.getDetailReport(sendData)
      } else if (sendData.reportForm === 'details') {
        send = await api.getDetailReport(sendData)
      } else {
        send = await api.getSummaryReport(sendData)
      }

      if (send) {
        setIsSearchPage(false)
        setTableData(send)
      }
      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setSendData({})
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/detailSQLlistRVBSDownloadExcel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/payrollReportController/getDetailSQLlistRVBSPdfTest`, 'application/pdf', data)
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isSearchPage && (
        <form>
          <Container maxWidth="md">
            <Grid container spacing={2}>
              {/* 承判商登記平均入息查詢 */}
              <Grid item xs={12}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  <b>{t('Contractor_registration_average_income_inquiry')}</b>
                </Typography>
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  error={!sendData.dateFrom}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: '2099-12-31',
                  }}
                  value={moment(new Date(sendData.dateFrom).getTime()).format('yyyy-MM-DD') || ''}
                  size="small"
                  label={t('date_from')}
                  type="date"
                  onChange={handleDateFrom}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  error={!sendData.dateTo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: '2099-12-31',
                    min: moment(new Date(sendData.dateFrom).getTime()).format('yyyy-MM-DD') || '',
                  }}
                  value={moment(new Date(sendData.dateTo).getTime()).format('yyyy-MM-DD') || ''}
                  size="small"
                  label={t('date_to')}
                  type="date"
                  onChange={handleDateTo}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={sendData.site || null}
                  onChange={handleSiteId}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.text
                  }}
                  options={siteList}
                  renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('icon1_autopay_setSiteUserMapping_tableLabel1')} />}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  value={sendData.vendor || null}
                  onChange={handleVendorId}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.text
                  }}
                  options={vendorIdList}
                  renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('icon1_personalentersearch_sub_condition')} />}
                />
              </Grid>

              <Grid item xs={6} container justifyContent="left">
                <Autocomplete
                  value={sendData.empType || null}
                  onChange={handleEmpTypeId}
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  getOptionLabel={(option) => {
                    return option.text
                  }}
                  options={empTypeIdList}
                  renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('Types_of_workers_feeding_methods')} />}
                />
              </Grid>

              <Grid item xs={6} />

              <Grid item xs={6}>
                <TextField error={!sendData.lowWage} value={sendData.lowWage || ''} fullWidth size="small" label={'工人入息(每日平均) 最低'} onChange={handleLowWage} />
              </Grid>
              <Grid item xs={6}>
                <TextField error={!sendData.highWage} value={sendData.highWage || ''} fullWidth size="small" label={t('工人入息(每日平均) 最高')} onChange={handlehighWage} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={!sendData.wageDiffSign}
                  value={sendData.wageDiffSign || ''}
                  fullWidth
                  size="small"
                  label={t('Difference_between_workers_income_and_registered_average_income')}
                  onChange={handleWageDiffSign}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl size="small" sx={{ width: '400px' }}>
                  <RadioGroup row onChange={handleLessOrGreat} defaultValue={lessOrGreat}>
                    <FormControlLabel label="小於" value="true" control={<Radio />} />
                    <FormControlLabel label="大於" value="false" control={<Radio />} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl size="small" sx={{ width: '400px' }}>
                  <RadioGroup row onChange={handleReportFormType} defaultValue={reportFormType}>
                    <FormControlLabel value="details" label="詳細記錄" control={<Radio />} />
                    {/* <FormControlLabel value="summy" label="一覽表" control={<Radio />} /> */}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6} />
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: 'primaryBlue',
                      color: 'commom.white',
                    }}
                  >
                    {t('button_submit')}
                  </Button>
                </FormControl>
                <FormControl sx={{ marginLeft: '20px' }}>
                  <Button
                    variant="contained"
                    onClick={resetForm}
                    sx={{
                      backgroundColor: 'primaryGreen',
                      color: 'commom.white',
                      marginRight: '10px',
                    }}
                    label="Reset"
                  >
                    {t('button_reset')}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}

      {!isSearchPage && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'block',
              textAlign: 'left',
              marginTop: '0px',
            }}
          >
            <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
              <ArrowBackIcon />
              {t('button_back')}
            </Button>
          </div>

          <div style={{ display: 'block', textAlign: 'right' }}>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
              <DownloadIcon />
              {t('button_export_excel')}
            </Button>
            <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
              <DownloadIcon />
              {t('button_export_pdf')}
            </Button>
          </div>
          <div>
            <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
              <b>{t('Contractor_registration_average_income_inquiry')}</b>
            </Typography>
            <TableContainer>
              <Table sx={{ textAlign: 'left' }} size="small">
                <TableBody>
                  <TableRow>
                    {/* 承判商： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('icon1_accrecsearch_sub_condition')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.vendorDesc}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 自動轉賬日期（由） */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('Autopay_date_by')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateFrom}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/*  自動轉賬日期（至）： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        width: 200,
                        borderBottom: 'none',
                      }}
                    >
                      {t('Autopay_date_to')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.dateTo}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 地盤名稱： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('Site_Name')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.site?.text || 'All'}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 工人出糧方式種類： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('Types_of_workers_feeding_methods')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>{sendData.empType?.text || '所有出糧方式'}</TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 工人入息(每日平均)：    */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('Workers_income_daily_average')}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                      {' '}
                      最低入息{sendData.lowWage} 最高入息{sendData.highWage}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {/* 工人入息與登記平均入息差額： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('Difference_between_workers_income_and_registered_average_income')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                      <Typography sx={{ display: 'block', fontSize: '15px' }}>
                        {sendData.lessOrGreat === 'true' ? '小於' : '大於'} {sendData.wageDiffSign}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    {/* 報表形式： */}
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        display: 'block',
                        borderBottom: 'none',
                      }}
                    >
                      {t('Report_form')}:
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', borderBottom: 'none' }}>
                      <Typography sx={{ display: 'block', fontSize: '15px' }}>{!sendData.reportForm ? '詳細記錄' : sendData.reportForm}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
              <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon1_estimated_employer_MPF_analysis_sub_condition')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('Autopay_date')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon1_autopay_maintWorkerAccount_label8')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon1_autopay_maintWorkerAccount_label9')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('Site_Name')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon1_useraccesslog_eng_name_label')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon1_useraccesslog_cn_name_label')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('icon1_autopay_distributeSubBatch_print_table_label5')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                        textAlign: 'right',
                      }}
                    >
                      {t('Worker_income')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                        textAlign: 'right',
                      }}
                    >
                      {t('icon1_autopay_button7_label6')}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                        textAlign: 'right',
                      }}
                    >
                      {t('Difference_between_workers_income_and_registered_average_income')}
                    </TableCell>
                  </TableRow>
                </TableHead>

                {!!tableData && tableData.length > 0 && (
                  <TableBody>
                    {tableData.map((row, ind) => (
                      <>
                        <TableRow
                          hover
                          key={ind}
                          sx={{
                            backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                          }}
                        >
                          <TableCell>{row.vendorDesc}</TableCell>
                          <TableCell>{row.autopayDate}</TableCell>
                          <TableCell>{row.dateFrom}</TableCell>
                          <TableCell>{row.dateTo}</TableCell>
                          <TableCell>{row.siteDesc}</TableCell>
                          <TableCell>{row.englishName}</TableCell>

                          <TableCell>{row.chineseName}</TableCell>
                          <TableCell>{row.hkid}</TableCell>

                          <TableCell sx={{ textAlign: 'right' }}>{row.relevantIncome.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          <TableCell sx={{ textAlign: 'right' }}>{row.basicSalary.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>

                          <TableCell sx={{ textAlign: 'right' }}>{row.wageDiff.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                )}

                {(!tableData || tableData.length === 0) && (
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center' }} colSpan={10}>
                        {'No data found !'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </Container>
      )}
    </>
  )
}
