import React, { useState } from 'react'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material'
import { CDialog } from 'src/components/common'
import { useNavigate, createSearchParams } from 'react-router-dom'
import ContactInformationForm from 'src/pages/otherDataSetting/ContactInformationForm'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import PropTypes from 'prop-types'
import AES from 'crypto-js/aes'

import { useTranslation } from 'react-i18next'

// mocks_
import { instance } from 'src/utils/instance'

import account from '../../../_mock/account'
// ----------------------------------------------------------------------
const CryptoJS = require('crypto-js')

const MENU_OPTIONS = [
  {
    label: 'Change Password',
    icon: 'eva:home-fill',
  },
]

// ----------------------------------------------------------------------

AccountPopover.propTypes = {
  handelDevice: PropTypes.func,
}
export default function AccountPopover(props) {
  const [open, setOpen] = useState(null)
  const [contactInformationOpen, setContactInformationOpen] = React.useState(false)
  const [changeEmailOpen, setChangeEmailOpen] = React.useState(false)

  const { t } = useTranslation()
  const { user, handelDevice } = props
  const navigate = useNavigate()
  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }
  const handleContactInformation = () => {
    setContactInformationOpen(true)
  }
  const handleChangeEmailOpen = (value) => {
    const Encrypt = (str, key = 'szewecszewecszew') => {
      const keys = CryptoJS.enc.Utf8.parse(key)
      const strs = CryptoJS.enc.Utf8.parse(str)
      const encrypted = CryptoJS.AES.encrypt(strs, keys, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      })
      // console.log('加密', encrypted.toString())
      return encrypted.toString()
    }
    const oneStep = Encrypt('1')
    const twoStep = Encrypt('2')

    navigate({
      pathname: '/homePage/changeEmail',
      search: `?${createSearchParams({
        step: user.email ? oneStep : twoStep,
      })}`,
    })
    handelDevice(value)
  }
  const handleFAQOpen = (value) => {
    handelDevice(value)
    navigate({
      pathname: '/homePage/FAQs',
    })
  }
  const handleLogout = () => {
    const accessToken = localStorage.getItem('access_token')
    const refreshToken = localStorage.getItem('refresh_token')
    console.log('註銷登錄')
    localStorage.clear()
    instance.post(`${process.env.REACT_APP_WEB_URL}/oauth/logoutToken`, { accessToken, refreshToken }).then(
      (result) => {
        return result.data.data
      },
      (error) => {
        alert('請求出錯')
        return []
      },
    )
    // instance.get(`${process.env.REACT_APP_WEB_URL}/oauth/logoutToken`).then(
    //   (result) => {
    //     return result.data.data
    //   },
    //   (error) => {
    //     alert('請求出錯')
    //     return []
    //   },
    // )
    setOpen(null)
    window.location.href = `${process.env.REACT_APP_OIDC_URL}/oidc/session/end?client_id=sanfield-smps&post_logout_redirect_uri=${process.env.REACT_APP_REDIRECTURL}/login`
  }

  const handleChangePWD = () => {
    window.location.href = `${process.env.REACT_APP_OIDC_CHANGE_PASSWORD_URL}/cuaext/web/changePassword?redirect_url=${process.env.REACT_APP_REDIRECTURL}/indexPage`
  }
  // const handleChangeEMA = () => {
  //   // window.location.href = `${process.env.REACT_APP_OIDC_CHANGE_PASSWORD_URL}/cuaext/web/changeEmail?redirect_url=${process.env.REACT_APP_REDIRECTURL}/indexPage`

  //   <ChangeEmail />
  // }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <DragIndicatorIcon sx={{ color: 'white' }} />
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ textAlign: 'left', my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography> */}
        </Box>

        {props?.user?.userType === 'EXT' && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Stack sx={{ width: 200 }}>
              <MenuItem onClick={handleChangePWD}>{t('change_password')}</MenuItem>
              <MenuItem onClick={handleChangeEmailOpen}>{t('change_email')}</MenuItem>
              <MenuItem onClick={handleContactInformation}>{t('contact_information')}</MenuItem>
            </Stack>
          </>
        )}
        <MenuItem onClick={handleFAQOpen}> {t('frequently_asked_questions')}</MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {t('logout')}
        </MenuItem>
      </Popover>
      {/* <ChangeEmail open={changeEmailOpen} onClose={() => setChangeEmailOpen(false)} /> */}
      <CDialog title={'Contact'} open={contactInformationOpen} onClose={() => setContactInformationOpen(false)}>
        <ContactInformationForm onClose={() => setVendorAuthorOpen(false)} />
      </CDialog>
    </>
  )
}
