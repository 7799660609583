import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Backdrop,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography,
  TextField,
  MenuItem,
  TableFooter,
} from '@mui/material'
import html2canvas from 'html2canvas'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DownloadIcon from '@mui/icons-material/Download'
import moment from 'moment'
import echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import JSPDF from 'jspdf'
import $http, { $download } from 'src/utils/api/services'

import { color, textAlign } from '@mui/system'
import * as api from '../../../utils/api/vendorAutopaySystem/RptVendorautopaystatuslist2SearchApi'

export default function RptVendorautopaystatuslist2Search() {
  const [isSearchPage, setIsSearchPage] = useState(true)
  // 國際化
  const { t } = useTranslation()
  const [backOpen, setBackOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [sendData, setSendData] = useState({})
  const [tableData, setTableData] = useState([])
  const [periodYearList, setPeriodYearList] = useState([])
  const [jobNatureIdList, setJobNatureIdList] = useState([])
  const contentRef = useRef(null)

  useEffect(() => {
    // setBackOpen(true)

    const getData = async () => {
      let getJobNatureIdList = await api.getfilljobNatureId()
      let getPeriodYearList = await api.getPeriodYear()

      if (getJobNatureIdList) {
        getJobNatureIdList.splice(0, 0, { id: '', text: 'ALL' })
        setJobNatureIdList(getJobNatureIdList)
      }

      if (getPeriodYearList) {
        setPeriodYearList(getPeriodYearList)
      }
    }
    getData()
  }, [])

  const handleBack = () => {
    setIsSearchPage(true)
  }

  const handlePeriodYear = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        dateFrom: data.id,
        periodYearDesc: data.text,
        periodYear: data,
      })
    } else {
      setSendData({
        ...sendData,
        dateFrom: null,
        periodYearDesc: null,
        periodYear: null,
      })
    }
  }

  const handleJobNatureId = (e, data) => {
    if (data !== null) {
      setSendData({
        ...sendData,
        jobNatureId: data.id,
        jobNatureIdDesc: data.text,
        jobNature: data,
      })
    } else {
      setSendData({
        ...sendData,
        jobNatureId: null,
        jobNatureIdDesc: null,
        jobNature: null,
      })
    }
  }

  const handleOrderBy = (e) => {
    setSendData({ ...sendData, orderBy: e.target.value })
  }

  const handleAscOrder = (e) => {
    setSendData({ ...sendData, ascOrder: e.target.value })
  }

  const handleSubmit = (e) => {
    if (!sendData.periodYear) {
      alert('請選擇日期')
    }
    const getData = async () => {
      setBackOpen(true)

      let send = await api.getRPTvendorautopaystatuslist2(sendData)

      if (send) {
        setIsSearchPage(false)
        setTableData(send)

        let nameArr = ['=0', '<=200', '201-300', '301-400', '401-600', '601-999', '1000']
        send.records.map((row, i) => {
          let arr = []
          let obj = {}
          arr.push(row.workerswithautopay0)
          arr.push(row.workerswithautopay200)
          arr.push(row.workerswithautopay300)
          arr.push(row.workerswithautopay400)
          arr.push(row.workerswithautopay600)
          arr.push(row.workerswithautopay999)
          arr.push(row.workerswithautopay1000)
          obj = {
            title: {
              text: row.vendorDesc + '工資分佈',
              // 其他标
              textStyle: {
                align: 'center',
              },
              align: 'center',
            },
            grid: {
              right: '25%',
              bottom: '33%',
              width: '50%',
            },
            xAxis: {
              type: 'category',
              data: nameArr,
              name: '工資分佈',
            },
            yAxis: {
              type: 'value',
              name: '工人數目',
            },
            series: [
              {
                data: arr,
                type: 'bar',
                smooth: false,
                barWidth: '60%',
              },
            ],
            tooltip: {
              trigger: 'axis',
            },
          }
          options.push(obj)
          return ''
        })

        setOptions(options)
      }
      setBackOpen(false)
    }
    getData()
  }

  const resetForm = (e) => {
    setSendData({})
  }

  const exportExcel = (data) => {
    $download(`/payrollReportController/getRPTvendorautopaystatuslist2Excel`, 'application/vnd.ms-excel', data)
  }

  const exportPDF = (data) => {
    $download(`/payrollReportController/getRPTvendorautopaystatuslist2Pdf`, 'application/pdf', data)
  }

  const handlePrint1 = async (htmlTitle, currentTime) => {
    let element = document.getElementById('contentRef')
    html2canvas(element, {
      logging: false,
    }).then(function (canvas) {
      let pdf = new JSPDF('l', 'mm', 'a4') // A4纸，纵向
      let ctx = canvas.getContext('2d')
      let a4w = 275
      let a4h = 300 // A4大小，210mm x 297mm，四边各保留20mm的边距
      let imgHeight = Math.floor((a4h * canvas.width) / a4w) // 按A4显示比例换算一页图像的像素高度
      let renderedHeight = 0

      while (renderedHeight < canvas.height) {
        let page = document.createElement('canvas')
        page.width = canvas.width
        page.height = Math.min(imgHeight, canvas.height - renderedHeight) // 可能内容不足一页 // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中

        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
        pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, (a4w * page.height) / page.width)) // 添加图像到页面，保留10mm边距

        renderedHeight += imgHeight
        if (renderedHeight < canvas.height) {
          pdf.addPage()
        } // 如果后面还有内容，添加一个空页 // delete page;
      }
      pdf.save(htmlTitle + currentTime)
    })
  }

  // 打印
  const handlePrint = async () => {
    try {
      const content = contentRef.current
      const scale = 0.4
      if (content) {
        const canvas = await html2canvas(content, { scale: scale })
        const imgData = canvas.toDataURL('image/png')

        // 创建一个新窗口用于打印
        const printWindow = window.open('', '', 'width=600,height=600')
        printWindow.document.open()
        printWindow.document.write('<img src="' + imgData + '" />')
        printWindow.document.close()

        // 等待图像加载完成后再执行打印
        printWindow.onload = function () {
          printWindow.print()
          printWindow.close()
        }
      }
    } catch (error) {
      console.error('打印出错：', error)
    }
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div id="contentRef">
        {isSearchPage && (
          <form>
            <Container maxWidth="md">
              <Grid>
                {/* 承判商行業自動轉帳出糧工資分佈一覽表(每月) */}
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ textAlign: 'center' }}>
                    <b>{t('List_of_wage_distribution_by_automatic_transfer_monthly_title')}</b>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    margin: 'auto',
                    width: '400px',
                    marginBottom: '20px',
                    marginTop: '10px',
                  }}
                >
                  <Autocomplete
                    value={sendData.jobNature || null}
                    onChange={handleJobNatureId}
                    fullWidth
                    size="small"
                    id="combo-box-demo"
                    getOptionLabel={(option) => {
                      return option.text
                    }}
                    options={jobNatureIdList}
                    renderInput={(params) => <TextField fullWidth size="small" {...params} label={t('Outfit')} />}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    margin: 'auto',
                    width: '400px',
                    marginBottom: '20px',
                    marginTop: '10px',
                  }}
                >
                  <Autocomplete
                    value={sendData.periodYear || null}
                    onChange={handlePeriodYear}
                    fullWidth
                    size="small"
                    id="combo-box-demo"
                    getOptionLabel={(option) => {
                      return option.text
                    }}
                    options={periodYearList}
                    renderInput={(params) => <TextField error={!sendData.periodYear} fullWidth size="small" {...params} label={t('date')} />}
                  />
                </Grid>

                <Grid item xs={6} />
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <FormControl>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{
                        backgroundColor: 'primaryBlue',
                        color: 'commom.white',
                      }}
                    >
                      {t('button_submit')}
                    </Button>
                  </FormControl>
                  <FormControl sx={{ marginLeft: '20px' }}>
                    <Button
                      variant="contained"
                      onClick={resetForm}
                      sx={{
                        backgroundColor: 'primaryGreen',
                        color: 'commom.white',
                        marginRight: '10px',
                      }}
                      label="Reset"
                    >
                      {t('button_reset')}
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}

        {!isSearchPage && (
          <Container maxWidth={false}>
            <div
              style={{
                display: 'block',
                textAlign: 'left',
                marginTop: '0px',
              }}
            >
              <Button sx={{ float: 'left', mr: '20px' }} onClick={handleBack}>
                <ArrowBackIcon />
                {t('button_back')}
              </Button>
            </div>

            <div style={{ display: 'block', textAlign: 'right' }}>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={() => handlePrint1(t('List_of_wage_distribution_by_automatic_transfer_title'), new Date())}>
                <DownloadIcon />
                {t('列印')}
              </Button>
              {/* <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportExcel(sendData)}>
                <DownloadIcon />
                {t('button_export_excel')}
              </Button>
              <Button sx={{ float: 'right', mr: '20px' }} onClick={() => exportPDF(sendData)}>
                <DownloadIcon />
                {t('button_export_pdf')}
              </Button> */}
            </div>
            <div>
              <Typography variant="h4" style={{ textAlign: 'center', mt: '-30px' }}>
                <b>
                  {t('List_of_wage_distribution_by_automatic_transfer_title')}({sendData.periodYearDesc} -- {sendData.jobNatureIdDesc})
                </b>
              </Typography>
              <Typography sx={{ textAlign: 'right' }}>
                {'列印日期'}:{moment(new Date().getTime()).format('yyyy/MM/DD')}
              </Typography>
              <TableContainer sx={{ maxHeight: '80vh', maxWidth: '100vw' }}>
                <Table size="small" sx={{ mt: '20px' }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {}
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {t('Name_of_the_contractor')}
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Number_of_non_participants')}
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Number_of_participants')}
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Total_number_of_people')}
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('percentage')}
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Number_of_non_participating_workers')}
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Number_of_participating_workers')}
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Total_number_of_work')}
                      </TableCell>

                      <TableCell
                        rowSpan={2}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('percentage')}
                      </TableCell>
                      <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                        工資分布
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {'=0'}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {'<=200'}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {'201-300'}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {'301-400'}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {'401-600'}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {'601-999'}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {'1000'}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {t('Average_wage')}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {!!tableData && tableData.records.length > 0 && (
                    <TableBody>
                      {tableData.records.map((row, ind) => (
                        <>
                          <TableRow
                            hover
                            key={ind}
                            sx={{
                              backgroundColor: ind % 2 !== 0 ? '#B0C4DE' : '#ffffff',
                            }}
                          >
                            <TableCell>{ind + 1}</TableCell>
                            <TableCell>{row.vendorDesc}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.workerswithoutautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.workerswithautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.totalworkers.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.workerswithautopaypercent.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.wdawithoutautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.wdawithautopay.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.totalwda.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.wdawithoutautopaypercent.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}%</TableCell>

                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.workerswithautopay0.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.workerswithautopay200.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.workerswithautopay300.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.workerswithautopay400.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.workerswithautopay600.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.workerswithautopay999.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.workerswithautopay1000.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>

                            <TableCell sx={{ textAlign: 'right', fontSize: '15px' }}>{row.avgsalarywithautopay.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  )}
                  {(!tableData || tableData.records.length === 0) && (
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ textAlign: 'center' }} colSpan={10}>
                          {'No data found !'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>

            <div style={{ margin: '5px' }}>
              <Grid item xs={2}>
                <Typography variant="h1" style={{ textAlign: 'right' }}>
                  <b>
                    {options.map((row, index) => (
                      <ReactEcharts key={index} option={row} />
                    ))}
                  </b>
                </Typography>
              </Grid>
            </div>
          </Container>
        )}
      </div>
    </>
  )
}
